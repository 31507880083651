import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'

function WaterUtilityApp() {
  return (
    <Layout>
      <SEO
        title="Water Utility App"
        keywords={[`gortek`, `app`, `startup`, `business`]}
      />
      <h3>Water Utility App</h3>
      <p>Proin et velit urna. Quisque consequat nibh nec nibh tincidunt malesuada. Fusce pellentesque est vitae malesuada maximus. Sed vestibulum ex a nulla varius commodo. Mauris vitae eros massa. Duis nisi diam, tempus vitae turpis at, aliquam iaculis dolor. Duis lobortis dolor vel lacus dictum, sit amet tristique quam auctor. Sed ac arcu vitae diam ornare convallis.</p>
    </Layout>
  )
}

export default WaterUtilityApp
